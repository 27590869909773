import "reactflow/dist/style.css";
import "./index.css";
import React, {
	useState,
	useRef,
	useCallback,
	useEffect,
	useContext,
} from "react";
import { ToastContainer } from "react-toastify";
import ReactFlow, {
	useNodesState,
	useEdgesState,
	addEdge,
	ReactFlowProvider,
	Controls,
	MiniMap,
	Background,
	MarkerType,
} from "reactflow";
import { MdSettings } from "react-icons/md";

import Sidebar from "./Sidebar";
import Condition from "../Nodes/Condition";
import Start from "../Nodes/Start";
import SendMessage from "../Nodes/SendMessage";
import Webhook from "../Nodes/Webhook";
import Question from "../Nodes/Question";
import CreateUpdateContact from "../Nodes/CreateUpdateContact";
import End from "../Nodes/End";
import Gush from "../Nodes/Gush";
import { useParams } from "react-router-dom";
import { serverApi } from "../../services/api";
import { generateId } from "../../services/generateId";
import SaveButton from "../Elements/SaveButton";
import Modal from "../Elements/ModalEdit/Modal";
import Jump from "../Nodes/Jump";
import SetVar from "../Nodes/SetVar";
import Sleep from "../Nodes/Sleep";
import ButtonEdge from "../Elements/ButtonEdge";
import CreateTask from "../Nodes/CreateTask";
import CreateDeal from "../Nodes/CreateDeal";
import { defaultVariables } from "../../helpers/default-variables";
import ModalVariable from "../Elements/ModalVariable";
import SetTag from "../Nodes/SetTag";
import { SettingsContainer } from "../Elements/ModalEdit/Settings";
import { AccountContext } from "../../context/Account";
import Comment from "../Nodes/Comment";

const nodeTypes = {
	conditional: Condition,
	start: Start,
	message: SendMessage,
	webhook: Webhook,
	question: Question,
	createupdatecontact: CreateUpdateContact,
	end: End,
	gush: Gush,
	jump: Jump,
	setvar: SetVar,
	settag: SetTag,
	sleep: Sleep,
	createtask: CreateTask,
	createdeal: CreateDeal,
	createnote: Comment,
};

const edgeTypes = {
	buttonedge: ButtonEdge,
};

let canOpenModal = true;

const Flow = () => {
	const { botId } = useParams();
	const { authUser, account } = useContext(AccountContext);
	const reactFlowWrapper = useRef(null);
	const edgeUpdateSuccessful = useRef(true);
	const [contactConditions, setContactConditions] = useState([]);
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [nodeToEdit, setNodeToEdit] = useState({});
	const [stateModalEdit, setStateModalEdit] = useState(false);
	const [variables, setVariables] = useState([]);
	const [botName, setBotName] = useState("");
	const [botSettings, setBotSettings] = useState({});
	const [owners, setOwners] = useState([]);
	const [operators, setOperators] = useState([]);
	const [dealPipelines, setDealPipelines] = useState([]);
	const [customDealFields, setCustomDealFields] = useState([]);
	const [contactFields, setContactFields] = useState([]);
	const [sectors, setSectors] = useState([]);
	const [chatTags, setChatTags] = useState([]);
	const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);

	const [modalActive, setModalActive] = useState("");
	const [editOpen, setEditOpen] = useState(false);
	const [nameVar, setNameVar] = useState("");
	const [elozProperty, setElozProperty] = useState("");

	const handleModalVariable = () => {
		setModalActive(!modalActive);
		setNameVar("");
	};

	function handleEditVariable() {
		setEditOpen((isOpen) => !isOpen);
	}

	const getVars = async () => {
		const resp = await serverApi.get(`/bot/variable/${botId}`);
		let respVars = resp.data;
		respVars.sort((a, b) => (defaultVariables.includes(a.name) ? -1 : 1));
		setVariables(resp.data);
	};

	const getSectors = async () => {
		try {
			const resp = await serverApi.get(`/sectors/${botId}`);
			setSectors(resp.data);
		} catch (e) {
			console.log(e);
			setSectors([]);
		}
	};

	const handleCustomVariables = async () => {
		const { data } = await serverApi.get("/bot/custom-variables");
		setContactConditions(data);
	};

	const getChatTags = async () => {
		try {
			const resp = await serverApi.get(`/tags/${botId}`);
			setChatTags(resp.data);
			return resp.data;
		} catch (e) {
			console.log(e);
			setChatTags([]);
			return [];
		}
	};

	const saveVar = async (e) => {
		if (!nameVar) {
			alert("Preencha todos os campos");
			return;
		}
		e.target.setAttribute("disabled", true);
		await serverApi.post(`/bot/variable/${botId}`, {
			name: nameVar,
			elozProperty,
		});
		e.target.removeAttribute("disabled");
		getVars();
		handleModalVariable();
	};

	async function handleEditVar(e, varId, elozProperty, name) {
		e.target.setAttribute("disabled", true);
		await serverApi.patch(`/bot/variable/${botId}/${varId}`, {
			elozProperty,
			name,
		});
		e.target.removeAttribute("disabled");
		getVars();
		handleEditVariable();
	}

	const getOperators = async () => {
		try {
			const resp = await serverApi.get(`/operators/${botId}`);
			setOperators(resp.data);
			return resp.data;
		} catch (e) {
			console.log(e);
			setOperators([]);
		}
	};

	const saveBot = async (e) => {
		try {
			e.target.setAttribute("disabled", true);
			await serverApi.post("/bot/save", {
				botId,
				nodes,
				edges,
				user: authUser,
			});
			alert("Bot salvo com sucesso");
			e.target.removeAttribute("disabled");
		} catch (err) {
			e.target.removeAttribute("disabled");
			alert("Erro ao salvar bot");
			console.log(err);
		}
	};

	const handleCustomDealFields = async (botId) => {
		let { data } = await serverApi.get(`/bot/eloz/fields/deal/custom/${botId}`);
		data = data.filter(
			(p) => !["contacts", "createdAt", "updatedAt"].includes(p.alias)
		);
		setCustomDealFields(data);
	};

	const handleContactFields = async (botId) => {
		const { data } = await serverApi.get(`/bot/eloz/fields/contact/${botId}`);
		setContactFields(data);
	};

	const handleGetElozOwnersByAccount = async (botId) => {
		const response = await serverApi.get(`/bot/eloz/owners/${botId}`);
		const owners = response.data;
		setOwners(owners);
	};

	const handleGetElozDealsPipelinesByAccount = async (botId) => {
		const response = await serverApi.get(`/bot/eloz/deal/pipelines/${botId}`);
		const pipelines = response.data;
		setDealPipelines(pipelines);
	};

	const getNodes = async (botId) => {
		const operators = await getOperators();
		const chattags = await getChatTags();
		const { data } = await serverApi.get(`/bot/${botId}`);

		const nodesArr = data.nodes.map((node) => {
			return {
				id: node.ref,
				...(node.type == "conditional" ? { type: "conditional" } : {}),
				...(node.type == "start" ? { type: "start" } : {}),
				...(node.type == "message" ? { type: "message" } : {}),
				...(node.type == "webhook" ? { type: "webhook" } : {}),
				...(node.type == "createupdatecontact"
					? { type: "createupdatecontact" }
					: {}),
				...(node.type == "question" ? { type: "question" } : {}),
				...(node.type == "end" ? { type: "end" } : {}),
				...(node.type == "gush" ? { type: "gush" } : {}),
				...(node.type == "jump" ? { type: "jump" } : {}),
				...(node.type == "setvar" ? { type: "setvar" } : {}),
				...(node.type == "settag" ? { type: "settag" } : {}),
				...(node.type == "sleep" ? { type: "sleep" } : {}),
				...(node.type == "createtask" ? { type: "createtask" } : {}),
				...(node.type == "createdeal" ? { type: "createdeal" } : {}),
				...(node.type == "createnote" ? { type: "createnote" } : {}),
				sourcePosition: "right",
				targetPosition: "left",
				data: {
					...node,
					botId,
					deleteNodeById,
					openModalEdit,
					currentNode: node,
					setNodes,
					operators,
					chattags,
				},
				position: node.position,
			};
		});

		const edgesArr = data.edges.map((edge) => {
			return {
				id: edge.id,
				source: edge.source,
				target: edge.target,
				type: "buttonedge",
				sourceHandle: `${edge.sourceHandle}`,
				markerEnd: {
					type: MarkerType.ArrowClosed,
				},
				data: {
					setEdges,
				},
			};
		});

		setNodes(nodesArr);
		setEdges(edgesArr);
		setBotName(data.name);
		setBotSettings(data.settings);
	};

	const handleModalEdit = () => {
		setStateModalEdit(!stateModalEdit);
	};

	const openModalEdit = (data) => {
		if (!stateModalEdit) {
			setNodeToEdit(data.data);
		}
		setStateModalEdit(!stateModalEdit);
		handleModalEdit();
	};

	const saveNode = (data) => {
		const x = nodes.map((node) => {
			if (data.question && data.question.includes("delay")) {
				data.question = data.question.replace("&lt;delay&gt;", "<delay>");
				data.question = data.question.replace("&lt;/delay&gt;", "</delay>");
			}

			if (data.msgMessage && data.msgMessage.includes("delay")) {
				data.msgMessage = data.msgMessage.replace("&lt;delay&gt;", "<delay>");
				data.msgMessage = data.msgMessage.replace("&lt;/delay&gt;", "</delay>");
			}

			if (node.id === data.ref) {
				node.data = {
					...node.data,
					...data,
				};
			}
			return node;
		});
		setNodes(x);
		handleModalEdit();
	};

	const deleteNodeById = (id) => {
		setNodes((nds) => nds.filter((node) => node.id !== id));
	};

	const onConnect = useCallback((params) => {
		canOpenModal = false;
		setEdges((eds) =>
			addEdge(
				{
					...params,
					type: "buttonedge",
					markerEnd: {
						type: MarkerType.ArrowClosed,
					},
					data: { setEdges },
				},
				eds
			)
		);
	}, []);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const onEdgeUpdateEnd = useCallback((_, edge) => {
		if (!edgeUpdateSuccessful.current) {
			setEdges((eds) => eds.filter((e) => e.id !== edge.id));
		}

		edgeUpdateSuccessful.current = true;
	}, []);

	const onDrop = useCallback(
		(event) => {
			event.preventDefault();
			const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
			const type = event.dataTransfer.getData("application/reactflow");

			// check if the dropped element is valid
			if (typeof type === "undefined" || !type) {
				return;
			}

			const position = reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top,
			});

			let newNode;
			const nodeId = generateId();

			newNode = {
				id: nodeId,
				type,
				position,
				sourcePosition: "right",
				targetPosition: "left",
				data: {
					position,
					botId,
					ref: nodeId,
					type,
					deleteNodeById,
					openModalEdit,
					setNodes,
					operators,
					chattags: chatTags,
				},
			};

			setNodes((nds) => nds.concat(newNode));
		},
		[reactFlowInstance, operators, chatTags]
	);

	useEffect(() => {
		getNodes(botId);
		handleGetElozOwnersByAccount(botId);
		handleGetElozDealsPipelinesByAccount(botId);
		handleCustomDealFields(botId);
		handleContactFields(botId);
		handleCustomVariables();
		getVars();
		getSectors();
	}, []);

	const handleMouseEvent = (style) => {
		return (e) => {
			const id =
				e?.target?.parentElement?.dataset?.testid?.split("rf__edge-")[1];
			if (!id) return;
			document.querySelector(`[data-line-action="${id}"]`).style.display =
				style;
		};
	};

	const handleKeyDown = (e) => {
		const id = e.target.parentElement.dataset.testid.split("rf__edge-")[1];
		setEdges((edges) => edges.filter((ed) => ed.id !== id));
	};

	function handleSettings() {
		setSettingsModalOpen((isOpen) => {
			return !isOpen;
		});
	}

	return (
		<>
			<ToastContainer />
			<SettingsContainer
				open={isSettingsModalOpen}
				handleSettings={handleSettings}
				botId={botId}
				sectors={sectors}
				operators={operators}
				settings={botSettings}
			/>
			<div className="dndflow" style={{ width: "100vw", height: "100vh" }}>
				<span className="titleBot">{botName}</span>
				<ReactFlowProvider>
					<div className="reactflow-wrapper" ref={reactFlowWrapper}>
						<ReactFlow
							nodes={nodes}
							edges={edges}
							multiSelectionKeyCode="Shift"
							onNodesChange={onNodesChange}
							onEdgesChange={onEdgesChange}
							onConnect={onConnect}
							onConnectEnd={() => {
								canOpenModal = false;
								setTimeout(() => {
									canOpenModal = true;
								}, 0);
							}}
							onEdgeDoubleClick={handleKeyDown}
							onEdgeMouseEnter={handleMouseEvent("unset")}
							onEdgeMouseLeave={handleMouseEvent("none")}
							onEdgeUpdateEnd={onEdgeUpdateEnd}
							onInit={setReactFlowInstance}
							onDrop={onDrop}
							edgeTypes={edgeTypes}
							onDragOver={onDragOver}
							fitView
							attributionPosition="bottom-left"
							nodeTypes={nodeTypes}
							deleteKeyCode={null}
							onNodeClick={(e, node, ...rest) => {
								if (["createupdatecontact", "end", "start"].includes(node.type))
									return;
								if (e.target.classList.contains("deleteNode")) return;
								if (e.target.classList.contains("editNode")) return;
								if (e.target.classList.contains("copy-ref")) return;

								if (canOpenModal) openModalEdit(node);
							}}
						>
							<Background
								variant="lines"
								gap={30}
								size={0}
								lineWidth={0.05}
								color="#fff"
								style={{ backgroundColor: "#454b6b" }}
							/>
							<MiniMap
								nodeColor={"#454b6b"}
								maskColor={"#4645457a"}
								nodeStrokeWidth={3}
								pannable
								zoomable={true}
								zoomStep={1}
								ariaLabel={`MiniMap ${botName}`}
							/>
							<Controls />
						</ReactFlow>
					</div>
					<Sidebar
						botId={botId}
						variables={variables}
						setVariables={setVariables}
						nodes={nodes}
						getVars={getVars}
						handleModalVariable={handleModalVariable}
						contactFields={contactFields}
						handleEditVar={handleEditVar}
						handleEditVariable={handleEditVariable}
						editOpen={editOpen}
					>
						<div className="bot-actions">
							<button
								className="action-button settings"
								title="Configurações gerais"
								onClick={handleSettings}
							>
								<MdSettings />
							</button>
							<SaveButton saveBot={saveBot}></SaveButton>
						</div>
					</Sidebar>

					<Modal
						botId={botId}
						nodeToEdit={nodeToEdit}
						active={stateModalEdit}
						nodes={nodes}
						edges={edges}
						setEdges={setEdges}
						handleModalEdit={handleModalEdit}
						saveNode={saveNode}
						variables={variables}
						setVariables={setVariables}
						owners={owners}
						customDealFields={customDealFields}
						pipelines={dealPipelines}
						operators={operators}
						handleModalVariable={handleModalVariable}
						sectors={sectors}
						tags={chatTags}
						contactFields={contactFields}
						contactConditions={contactConditions}
					></Modal>

					<ModalVariable
						active={modalActive}
						handleModalVariable={handleModalVariable}
						saveVar={saveVar}
						nameVar={nameVar}
						setNameVar={setNameVar}
						contactFields={contactFields}
						setElozProperty={setElozProperty}
						elozProperty={elozProperty}
						account={account}
					></ModalVariable>
				</ReactFlowProvider>
			</div>
		</>
	);
};

export default Flow;
