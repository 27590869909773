import "./index.css";
import React, { memo } from "react";
import { FaCodeBranch, FaPlus } from "react-icons/fa";
import { Handle } from "reactflow";

import nameConditions from "../../../helpers/nameConditions";

import { ValidatorFn } from "../../../helpers/validationConnector";
import { NodeHeader } from "../../Elements/NodeHeader";
import { NodeRef } from "../../Elements/NodeRef";

const makeNameExibition = (data) => {
	const condVar = data.condVar || "";
	const condName = nameConditions[data.condType] || "";
	const condValue = data.condValue || "";
	return (
		<>
			<span>@{condVar}</span> <b>{condName}</b> {condValue}
		</>
	);
};

export default memo(({ data, ...rest }) => {
	return (
		<>
			<NodeRef nodeRef={data.ref} />

			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				rest={rest}
				data={data}
				icon={<FaCodeBranch />}
				text="Condicional"
			/>

			<div className="sendMessageContent">
				<div>{makeNameExibition(data)}</div>
			</div>

			<Handle
				type="source"
				position="right"
				id="true"
				className="handleTrue"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
			<Handle
				type="source"
				position="right"
				id="false"
				className="handleFalse"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
		</>
	);
});
