import React, { memo } from "react";
import { FaComment } from "react-icons/fa";

import { NodeHeader } from "../../Elements/NodeHeader";
import styled from "styled-components";

const Comment = styled.div`
	background-color: rgb(255, 248, 226);
	border-radius: 4px;

	.sendMessageContent {
		background-color: rgb(255, 248, 226);
	}

	.sendMessageContent div {
		background-color: rgb(255, 248, 226);
		padding: 0 5px;
	}
`;

export default memo(({ data, ...rest }) => {
	return (
		<Comment>
			<NodeHeader rest={rest} data={data} icon={<FaComment />} text="Nota" />

			<div className="sendMessageContent">
				<div>{data?.comment}</div>
			</div>
		</Comment>
	);
});
