import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
	background-color: #1a192b;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const Header = styled.header`
	position: relative;
	width: 100%;
	background-color: #007bff;
	color: #ffffff;
	padding: 60px 20px;
	box-sizing: border-box;

	& img {
		width: 100px;
		margin: 10px 0;
	}

	& h1 {
		font-size: 3em;
		margin: 0;
		position: relative;
		z-index: 1;
	}

	& p {
		font-size: 1.2em;
		margin: 10px 0 0;
	}
`;

export const Content = styled.div`
	padding: 40px 20px;
	display: flex;
	justify-content: center;
	gap: 40px;

	& .feature {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 300px;
		background-color: #edeef9;
		border-radius: 8px;
		padding: 10px;
	}

	& .feature .icon {
		font-size: 3em;
		color: #007bff;
		margin-bottom: 10px;
	}

	& .feature h2 {
		font-size: 1.5em;
		margin: 10px 0;
		color: #1a192b;
	}

	& .feature p {
		font-size: 1em;
		color: #666;
	}
`;

export const Footer = styled.footer`
	margin-top: 40px;
	font-size: 0.9em;
	color: #777;
`;
