import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AccountProvider } from "./context/Account";
import { Unauthorized } from "./pages/unauthorized";
import Flow from "./components/Flow/Flow";
import { Intro } from "./pages/intro";
import { NotFound } from "./pages/notFound";

function App() {
	return (
		<BrowserRouter>
			<AccountProvider>
				<Routes>
					<Route path="/" element={<Intro />}></Route>
					<Route path="/builder/:botId" element={<Flow />}></Route>
					<Route path="/unauthorized" element={<Unauthorized />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
			</AccountProvider>
		</BrowserRouter>
	);
}

export default App;
