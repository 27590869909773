import React from "react";
import { FaHammer, FaRobot } from "react-icons/fa";
import { Container, Content, Footer, Header } from "./styles/default";

function Intro() {
	return (
		<Container>
			<Header>
				<img src="eloz-icon-white.svg" alt="Rvops CRM" />
				<h1>Builder</h1>
				<p>Build and automate your channels easy as drink water.</p>
			</Header>
			<Content>
				<div className="feature">
					<FaHammer className="icon" />
					<h2>Build</h2>
					<p>Utilize powerful tools to construct your projects efficiently.</p>
				</div>
				<div className="feature">
					<FaRobot className="icon" />
					<h2>Automate</h2>
					<p>Automate repetitive tasks and streamline your workflow.</p>
				</div>
			</Content>
			<Footer>
				<p>&copy; 2024 Rvops CRM. All rights reserved.</p>
			</Footer>
		</Container>
	);
}

export { Intro };
