import React, { memo } from "react";
import { FaUserNinja } from "react-icons/fa";
import { Handle } from "reactflow";

import { NodeHeader } from "../../Elements/NodeHeader";
import { NodeRef } from "../../Elements/NodeRef";

export default memo(({ data, ...rest }) => {
	function nodeHeader() {
		if (data.gushOperators && data.gushChoose === "operator") {
			const operatorSelected = data.operators.find(
				(op) => op._id === data.gushOperators[0]
			);
			return (
				<>
					Transferir para atendente <b>@{operatorSelected?.name}</b>
				</>
			);
		}
		if (data.gushSector) {
			return (
				<>
					Transferir para atendente do setor <b>@{data.gushSector}</b>
				</>
			);
		}
	}
	return (
		<>
			<NodeRef nodeRef={data.ref} />
			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				data={data}
				rest={rest}
				icon={<FaUserNinja />}
				text="Transferir para atendente"
			/>

			<div className="sendMessageContent">
				<div>{nodeHeader()}</div>
			</div>
		</>
	);
});
