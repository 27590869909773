import "./index.css";
import React, { memo } from "react";
import { FaToggleOn } from "react-icons/fa";
import { Handle } from "reactflow";

import { NodeHeader } from "../../Elements/NodeHeader";
import { NodeRef } from "../../Elements/NodeRef";

export default memo(({ data, ...rest }) => {
	return (
		<>
			<NodeRef nodeRef={data.ref} />
			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				rest={rest}
				data={data}
				icon={<FaToggleOn />}
				text="Encerrar conversa"
			/>
		</>
	);
});
