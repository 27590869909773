import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { serverApi, spreadApi } from "../services/api";
import { useNavigate } from "react-router-dom";

export const AccountContext = createContext();

const AccountProvider = ({ children }) => {
	const [account, setAccount] = useState({});
	const [authUser, setAuthUser] = useState({});
	const navigate = useNavigate();

	const getAccount = async (botId) => {
		try {
			const resp = await serverApi.get(`/account/${botId}`);
			setAccount(resp.data);
		} catch (e) {
			console.log(e);
		}
	};

	async function handleAuthUser(botid) {
		try {
			const queryString = window.location.search;
			const url = new URLSearchParams(queryString);
			const token = url.get("authuser");

			const { data } = await spreadApi.post("/eloz/builder/authuser", {
				token,
			});
			setAuthUser(data);
			getAccount(botid);
		} catch (error) {
			navigate("/unauthorized");
		}
	}

	useEffect(() => {
		const botid = window.location.pathname.split("/builder/")[1];
		if (botid) {
			handleAuthUser(botid);
		}
	}, []);

	return (
		<AccountContext.Provider value={{ account, authUser }}>
			{children}
		</AccountContext.Provider>
	);
};

export { AccountProvider };
