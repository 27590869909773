import React from "react";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

function NodeRef({ nodeRef }) {
	function handleCopyRef() {
		navigator.clipboard.writeText(nodeRef);
		toast.success("Referência copiada para área de transferência.", 5_000);
	}

	return (
		<div className="nodeRef">
			<label>Ref: {nodeRef}</label>
			<button
				title="Copiar referência"
				className="copy-ref"
				onClick={handleCopyRef}
			>
				<FaRegCopy size={6} fill="#c4c8dd" />
			</button>
		</div>
	);
}

export { NodeRef };
