import React from "react";
import { FaPlus } from "react-icons/fa";

import { defaultVariables } from "../../helpers/default-variables";

const AddVariablesInText = (props) => {
	const addVarContent = (x) => {
		var sel, range;

		const variableToInsert = x.target.textContent;

		const isDefaultVar = defaultVariables.includes(variableToInsert);

		const html = `<span contenteditable="false" class="variableInText ${
			isDefaultVar ? "defaultVar" : ""
		}">{{${variableToInsert}}}</span>`;
		if (window.getSelection) {
			sel = window.getSelection();
			if (sel.getRangeAt && sel.rangeCount) {
				range = sel.getRangeAt(0);

				const isValid =
					range?.commonAncestorContainer?.classList?.contains(
						"inputTextArea"
					) || range?.commonAncestorContainer?.classList?.contains("inputText");

				if (
					range.commonAncestorContainer.parentElement.closest(
						".inputTextArea"
					) ||
					range.commonAncestorContainer.parentElement.closest(".inputText") ||
					isValid
				) {
					range.deleteContents();
					var el = document.createElement("div");
					el.innerHTML = html;
					var frag = document.createDocumentFragment(),
						node,
						lastNode;
					while ((node = el.firstChild)) {
						lastNode = frag.appendChild(node);
					}

					range.insertNode(frag);
					if (lastNode) {
						range = range.cloneRange();
						range.setStartAfter(lastNode);
						range.collapse(true);
						sel.removeAllRanges();
						sel.addRange(range);
					}
				}
			}
		} else if (document.selection && document.selection.type !== "Control") {
			document.selection.createRange().pasteHTML(html);
		}

		document
			.querySelectorAll(".inputTextArea span:not(.variableInText)")
			.forEach((sp) => sp.remove());
	};

	return (
		<div className="content_variables">
			<small>
				Para adiconar variáveis nos campos de texto, selecione abaixo:
			</small>
			<br></br>
			<div>
				{props.variables.map((varx) => (
					<button
						key={varx.name}
						className={`opt-variable-modal ${
							defaultVariables.includes(varx.name) ? "defaultVar" : ""
						}`}
						onClick={addVarContent}
					>
						{varx.name}
					</button>
				))}
			</div>
			{props.showCreateVarButton && (
				<button className="create-variable" onClick={props.handleModalVariable}>
					Criar variável <FaPlus size={10} />
				</button>
			)}
		</div>
	);
};

export default AddVariablesInText;
