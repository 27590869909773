import React, { useEffect, useState } from "react";
import { MdClose, MdOutlineSettings } from "react-icons/md";

import "./settings.css";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { serverApi } from "../../../services/api";

import helpNode from "../../../assets/img/help-node.png";
import { HelpMessage } from "../HelpMessage";

function SettingsContainer({
	open,
	handleSettings,
	botId,
	sectors,
	operators,
	settings,
}) {
	const [goToActions, setGoToActions] = useState([]);
	const [messagePings, setMessagePings] = useState([]);
	const [actionAfterLastPing, setActionAfterLastPing] = useState({
		action: "end",
		transferType: "", // sectorId, operatorId
		transferValue: "",
		time: 120,
		value: "",
	});
	const [showTransferTypes, setShowTransferTypes] = useState(false);
	const [showGoToAction, setShowGoToAction] = useState(false);
	const [transferType, setTransferType] = useState("sectorId");

	const actionsPing = [
		{
			value: "end",
			label: "Encerrar conversa",
		},
		{
			value: "transfer",
			label: "Transferir para atendimento",
		},
		{
			value: "go_to_action",
			label: "Go to Action",
		},
	];

	const transferTypes = [
		{
			value: "sectorId",
			label: "Setor",
		},
		{
			value: "operatorId",
			label: "Atendente",
		},
	];

	useEffect(() => {
		if (settings?.goToActions) {
			setGoToActions(settings.goToActions);
			setMessagePings(settings.messagePings);
			setActionAfterLastPing(settings.actionAfterLastPing);
			setShowTransferTypes(settings.actionAfterLastPing.action === "transfer");
			setShowGoToAction(settings.actionAfterLastPing.action === "go_to_action");
			setTransferType(settings.actionAfterLastPing.transferType);
		}
	}, [settings]);

	async function handleSaveSettings() {
		const hasSomeActionEmpty = goToActions.some(
			(op) => !op.keyword.trim() || !op.ref.trim()
		);

		if (goToActions.length && hasSomeActionEmpty) {
			toast.warn("Preencha corretamente as ações de Go To Action.", 5_000);
			return;
		}

		const hasSomePingEmpty = messagePings.some(
			(op) => !op.message.trim() || !op.time
		);

		if (messagePings.length && hasSomePingEmpty) {
			toast.warn("Preencha corretamente as ações de inatividade.", 5_000);
			return;
		}

		if (messagePings.length) {
			// validar se a soma passe de 24h
			const isMoreThen24h =
				messagePings.reduce((prev, curr) => (prev += curr.time / 60), 0) > 24;
			if (isMoreThen24h) {
				toast.warn(
					"A soma total dos pings de mensagem não pode passar de 24h."
				);
				return;
			}
		}

		const isActionMoreThen24h = actionAfterLastPing.time / 60 > 24;

		if (isActionMoreThen24h) {
			toast.warn("A o tempo da última ação não pode passar de 24h.");
			return;
		}

		try {
			await serverApi.patch(`/bot/settings/${botId}`, {
				messagePings,
				goToActions,
				actionAfterLastPing,
			});

			toast.success("Configurações salvas com sucesso.", 5_000);
			handleSettings();
		} catch (error) {
			console.error({
				message: error.message,
				stack: error.stack,
				context: "handleSaveSettings",
			});
		}
	}

	function removePingOption(id) {
		return () => {
			setMessagePings((pings) => {
				return pings.filter((ping) => ping.id != id);
			});
		};
	}

	function removeActionOption(id) {
		return () => {
			const filtered = goToActions.filter((action) => action.id != id);
			setGoToActions(filtered);
		};
	}

	function addOption(type) {
		return () => {
			if (type === "action") {
				const id = Date.now();
				if (goToActions.length + 1 > 3) {
					toast.warn("Limite de 3 Go To Actions atingido.");

					return;
				}

				setGoToActions((options) => {
					const hasSomeEmpty = options.some(
						(op) => !op.keyword.trim() || !op.ref.trim()
					);

					if (hasSomeEmpty) return options;

					return [
						...options,
						{
							id,
							keyword: "",
							ref: "",
						},
					];
				});
			}
			if (type === "ping") {
				const id = Date.now();

				if (messagePings.length + 1 > 5) {
					toast.warn("Limite de 5 Pings de Mensagem atingido.");
					return;
				}

				setMessagePings((options) => {
					const hasSomeEmpty = options.some(
						(op) => !op.message.trim() || !op.time
					);

					if (hasSomeEmpty) return options;

					return [
						...options,
						{
							id,
							message: "",
							time: 0,
						},
					];
				});
			}
		};
	}

	function handleChangeOption(action, key, obj = {}) {
		return (e) => {
			if (action === "action") {
				setGoToActions((options) => {
					return options.map((op) => {
						if (op.id == obj.id) {
							op[key] = e.target.value;
						}
						return op;
					});
				});
			}
			if (action === "ping") {
				setMessagePings((options) => {
					return options.map((op) => {
						if (op.id == obj.id) {
							op[key] = e.target.value;
						}
						return op;
					});
				});
			}
			if (action === "action-ping") {
				setActionAfterLastPing({
					...actionAfterLastPing,
					[key]: e.target.value,
				});
			}
		};
	}

	function handleActionLastPing(e) {
		const value = e.target.value;

		setShowTransferTypes(value === "transfer");
		setShowGoToAction(value === "go_to_action");
		setActionAfterLastPing({
			...actionAfterLastPing,
			action: value,
		});
	}

	return (
		<div className={`backdrop-modal ${open ? "" : "hide"}`}>
			<div className="modal-container">
				<div className="modal-header">
					<MdOutlineSettings size={22} fill="#454962" />
					<div>
						<span>Configurações</span>
					</div>
					<div className="modal-header--close" onClick={handleSettings}>
						<MdClose size={22} fill="#454962" />
					</div>
				</div>
				<div className="modal-content">
					<div className="setting-group">
						<h3 className="setting-group-title">
							Go to Action
							<button title="Adicionar" onClick={addOption("action")}>
								<FaPlus size={15} fill="#454962" />
							</button>
						</h3>
						<div className="help">
							<span>
								Go to Action pode ter até 3 palavras-chave com seu respectivo
								nó.
							</span>
							<span>
								Quando o contato responder com qualquer destas palavras,
								automáticamente o bot irá para o nó selecionado.
							</span>
						</div>
						<div className="inputs-group">
							{goToActions.map((goToAction) => (
								<div key={goToAction.id}>
									<div className="input-group">
										<label htmlFor="">Palavra chave:</label>
										<input
											type="text"
											className="inputText"
											placeholder="Ex: Voltar"
											onChange={handleChangeOption(
												"action",
												"keyword",
												goToAction
											)}
											value={goToAction.keyword}
										/>
									</div>
									<div className="input-group">
										<label htmlFor="" className="input-group-help">
											Nó:
											<HelpMessage message="Nó é o id de cada bloco. Você pode copiar clicando no botão conforme imagem abaixo:">
												<img src={helpNode} alt="" />
											</HelpMessage>
										</label>
										<input
											type="text"
											className="inputText"
											placeholder="Ex: 123-456-789"
											onChange={handleChangeOption("action", "ref", goToAction)}
											value={goToAction.ref}
										/>
									</div>
									<button onClick={removeActionOption(goToAction.id)}>
										<FaTrash />
									</button>
								</div>
							))}
						</div>
					</div>
					<div className="setting-group">
						<h3 className="setting-group-title">
							Definir inatividade
							<button title="Definir" onClick={addOption("ping")}>
								<FaPlus size={15} fill="#454962" />
							</button>
						</h3>
						<div className="help">
							<span>
								Os <i>pings</i> de mensagem são mensagens enviadas em períodos
								de tempo definidos até o encerramento da conversa ou
								transferencia para o atendimento humano.
							</span>
							<span>
								O limite máximo são 5 <i>pings</i> com a soma total de 24h.
							</span>
						</div>
						<div className="inputs-group">
							{messagePings.map((ping) => (
								<div key={ping.id}>
									<div className="input-group">
										<label htmlFor="">Mensagem:</label>
										<input
											type="text"
											className="inputText"
											placeholder="Ex: Oii, ainda está ai?"
											value={ping.message}
											onChange={handleChangeOption("ping", "message", ping)}
										/>
									</div>
									<div className="input-group">
										<label htmlFor="">Tempo (em minutos):</label>
										<input
											type="number"
											min={0}
											inputMode="numeric"
											className="inputText"
											placeholder="Ex: 5"
											value={ping.time}
											onChange={handleChangeOption("ping", "time", ping)}
										/>
									</div>
									<button onClick={removePingOption(ping.id)}>
										<FaTrash fill="#454962" />
									</button>
								</div>
							))}
							{messagePings.length ? (
								<>
									<hr />
									<div>
										<div className="input-group">
											<label htmlFor="">Ação após último ping:</label>
											<select
												onChange={handleActionLastPing}
												value={actionAfterLastPing.action}
											>
												{actionsPing.map((action) => (
													<option value={action.value} key={action.value}>
														{action.label}
													</option>
												))}
											</select>
										</div>
										<div className="input-group">
											<label htmlFor="">Tempo (em minutos)</label>
											<input
												type="number"
												min={0}
												inputMode="numeric"
												className="inputText"
												placeholder="Ex: 120"
												value={actionAfterLastPing.time}
												onChange={handleChangeOption("action-ping", "time")}
											/>
										</div>
									</div>
									{showTransferTypes ? (
										<div>
											<div className="input-group">
												<label htmlFor="">Tipo de transferência:</label>
												<select
													name=""
													id=""
													onChange={(e) => {
														setTransferType(e.target.value);
														setActionAfterLastPing({
															...actionAfterLastPing,
															transferType: e.target.value,
														});
													}}
													value={actionAfterLastPing.transferType}
												>
													{transferTypes.map((action) => (
														<option value={action.value} key={action.value}>
															{action.label}
														</option>
													))}
												</select>
											</div>
											<div className="input-group">
												<label htmlFor="">Selecione uma opção:</label>
												<select
													name=""
													id=""
													onChange={(e) => {
														setActionAfterLastPing({
															...actionAfterLastPing,
															transferValue: e.target.value,
														});
													}}
													value={actionAfterLastPing.transferValue}
												>
													{transferType === "sectorId" &&
														sectors.map((sector) => (
															<option key={sector} value={sector}>
																{sector}
															</option>
														))}
													{transferType === "operatorId" &&
														operators.map((operator) => (
															<option value={operator._id} key={operator._id}>
																{operator.name} (
																{`${operator?.sector?.name ?? "-"}`})
															</option>
														))}
												</select>
											</div>
										</div>
									) : null}
									{showGoToAction ? (
										<div>
											<div className="input-group">
												<label htmlFor="">Nó</label>
												<input
													type="text"
													className="inputText"
													placeholder="Ex: 123-456-789"
													value={actionAfterLastPing.value}
													onChange={handleChangeOption("action-ping", "value")}
												/>
											</div>
										</div>
									) : null}
								</>
							) : null}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button onClick={handleSettings} className="btnModal btnCancel">
						Cancelar
					</button>
					<button
						onClick={handleSaveSettings}
						className="btnModal btnSaveModal"
					>
						Salvar
					</button>
				</div>
			</div>
		</div>
	);
}

export { SettingsContainer };
