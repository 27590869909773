import React, { useState } from "react";
import "./elements.css";
import { MdClose } from "react-icons/md";

import { handleHeaderByType } from "../../helpers/header-action";

const EditVariable = (props) => {
	const [elozProperty, setElozProperty] = useState("");

	async function handleEditVar(e) {
		await props.handleEditVar(
			e,
			props.variable._id,
			elozProperty,
			props.variable.name
		);
	}

	return (
		<>
			<div className={`modalBlackout ${props.active ? "" : "modalHide"}`}></div>
			<div className={`modalDiv ${props.active ? "" : "modalHide"}`}>
				<div className="modalHeader">
					{handleHeaderByType("editVar")}
					<div className="modalHeader-close" onClick={props.handleEditVariable}>
						<MdClose size={22} />
					</div>
				</div>
				<div className="modalContent">
					<div className="node-question">
						<div>
							<label>Nome da variável</label>
							<input
								type="text"
								className="inputText"
								value={props.variable.name}
								placeholder="Ex: eh_cliente"
								disabled
							/>
						</div>
						{props.contactFields?.length && (
							<div className="input-group">
								<label>Associar à propriedade</label>
								<select
									onChange={(e) => {
										setElozProperty(e.target.value);
									}}
								>
									<option value=""></option>
									{props.contactFields.map((field) => (
										<option
											key={field.id}
											value={field.alias}
											selected={
												props.variable.propertyAssociate === field.alias
											}
										>
											{field.label}
										</option>
									))}
								</select>
							</div>
						)}
					</div>
				</div>
				<div className="modalBottom">
					<button
						onClick={props.handleEditVariable}
						className="btnModal btnCancel"
					>
						Cancelar
					</button>
					<button onClick={handleEditVar} className="btnModal btnSaveModal">
						Salvar
					</button>
				</div>
			</div>
		</>
	);
};

export { EditVariable };
