import axios from "axios";

let urlServer,
	urlSpread = "";
if (process.env.NODE_ENV === "development") {
	urlServer = "http://localhost:4040";
	urlSpread = "http://localhost:5000";
} else {
	urlServer = "https://bot.spread.chat";
	urlSpread = "https://server.spread.chat";
}

const serverApi = axios.create({
	baseURL: urlServer,
});

const spreadApi = axios.create({
	baseURL: urlSpread,
});

export { serverApi, spreadApi };
