import React from "react";
import { MdHelpOutline } from "react-icons/md";
import styled from "styled-components";

const HelpContainer = styled.div`
	position: relative;
	display: flex;
	z-index: 2;

	&:hover .help-content {
		display: flex;
	}
`;
const HelpContent = styled.div`
	position: absolute;
	left: 20px;
	top: -3px;
	display: none;
	width: 250px;
	padding: 10px;
	border-radius: 8px;

	background-color: #fff;
	box-shadow: 0 0 10px 0px rgb(127 127 127 / 36%);

	flex-direction: column;

	& span {
		font-size: 14px;
		margin: 0 0 5px 0;
	}

	& img {
		display: block;
		width: 100%;
		border-radius: 5px;
		pointer-events: none;
	}
`;

function HelpMessage({ message, children }) {
	return (
		<HelpContainer>
			<MdHelpOutline />
			<HelpContent className="help-content">
				<span>{message}</span>
				{children}
			</HelpContent>
		</HelpContainer>
	);
}

export { HelpMessage };
