import React from "react";
import { Container, Content, Footer, Header } from "./styles/default";
import { FaLock } from "react-icons/fa";

function Unauthorized() {
	return (
		<Container>
			<Header>
				<img src="eloz-icon-white.svg" alt="Rvops CRM" />
			</Header>
			<Content>
				<div className="feature">
					<FaLock className="icon" />
					<h2>Unauthorized</h2>
					<p>Ops... You are not allowed to use Eloz Builder.</p>
				</div>
			</Content>
			<Footer>
				<p>&copy; 2024 Rvops CRM. All rights reserved.</p>
			</Footer>
		</Container>
	);
}

export { Unauthorized };
