function handlePropertyVar(variable, contactFields) {
	const field = contactFields.find(
		(f) => f.alias === variable.propertyAssociate
	);

	if (field) {
		return `${field.label}`;
	}
}

export { handlePropertyVar };
