import React from "react";

import "./elements.css";

export default function SaveButton(props) {
	return (
		<button className="btnSave action-button" onClick={props.saveBot}>
			Publicar
		</button>
	);
}
